<template>
  <div id="questionnaire-page" v-if="questionnaire.length > 0">
    <h1>Questionnare Details</h1>
    <div v-if="questionnaire.length > 0" class="questionnaire-section mt-4">
      <div
        v-for="(question, idx) in questionnaire"
        :key="'question' + idx"
        class="question"
      >
        <h4>{{ question.question.question }}</h4>
        <template v-if="question.question.type === 'MCQ'">
          <b-form-group>
            <b-form-radio
              v-for="(opt, idx) in question.question.options"
              :key="'option' + idx"
              :name="'mcq-question-' + question.question.id"
              v-model="question.answer"
              disabled
              :value="opt.value"
              >{{ opt.text }}</b-form-radio
            >
          </b-form-group>
        </template>
        <template v-else>
          <b-form-textarea
            id="textarea"
            placeholder="Enter something..."
            rows="3"
            v-model="question.answer"
            disabled
            max-rows="6"
          ></b-form-textarea>
        </template>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="btn-primary" @click="$router.go(-1)">Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getDoctorQuestionniare({
      eventId: this.$route.params.eventId,
      doctorId: this.$route.params.doctorId,
    });
  },
  methods: {
    ...mapActions({
      getDoctorQuestionniare: "getDoctorQuestionniare",
    }),
  },
  computed: {
    ...mapGetters({
      questionnaire: "questionnaire",
    }),
  },
};
</script>

<style lang="scss" scoped>
#questionnaire-page {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  .questionnaire-section {
    .question {
      border: 1px solid lightgray;
      padding: 10px;
      margin-bottom: 12px;
      .form-group {
        margin-bottom: 0 !important;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #3c4eba;
        margin-bottom: 10px !important;
      }
    }

    .btn-primary,
    .btn-success {
      width: 200px;
    }
  }
}
</style>
